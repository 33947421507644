/* eslint-disable import/no-unresolved */
import React, { useContext } from "react";
import styles from "./CallingPopup.module.scss";
import Image from "next/image";
import Lottie from "react-lottie";
import { CALLING_POPUP_ICONS, CHAT_ICON } from "@/utils/imageRelativePaths";
import * as animationData from "../../../public/assets/lotties/CallingLottie.json";
import { AppContext } from "@/context/AppContext";
import { endCall } from "@/services/call.service";
import { callPopupENUM } from "@/utils/common.types";
import { cancelChatInvite } from "@/services/chat.service";

const CallingPopup = ({ type }: { type: callPopupENUM }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const {
    privateCallData,
    zegoClient,
    privateCallSocket,
    paidDmSocket,
    chatData,
    setChatData,
  } = useContext(AppContext) ?? {};

  return (
    <div className={styles.CallingPopupWraper}>
      <Image
        src={
          type === callPopupENUM.CALL
            ? privateCallData?.caller?.photo ?? ""
            : (chatData?.initiator?.user?.profilePic as string) ?? ""
        }
        alt="user"
        height={46}
        width={46}
      />
      <div className={styles.lottie}>
        {type === callPopupENUM.CALL ? (
          <>
            {/* @ts-expect-error Server Component */}
            <Lottie options={defaultOptions} height={33} width={71} />
          </>
        ) : (
          <Image src={CHAT_ICON} alt="chat icon" height={30} width={40} />
        )}
      </div>
      <Image
        src={
          type === callPopupENUM.CALL
            ? privateCallData?.contentOwner?.photo ?? ""
            : chatData?.receiver?.user?.photo ?? ""
        }
        alt="user"
        height={46}
        width={46}
      />
      <button
        onClick={() => {
          if (type === callPopupENUM.CALL) {
            privateCallSocket &&
              zegoClient &&
              void endCall(zegoClient, privateCallSocket);
          } else {
            paidDmSocket &&
              chatData &&
              chatData?.session?.roomId &&
              setChatData &&
              void cancelChatInvite(
                paidDmSocket,
                chatData?.session?.roomId,
                setChatData
              );
          }
        }}
      >
        <Image
          src={CALLING_POPUP_ICONS.CALLING_ICON}
          alt="user"
          height={25}
          width={25}
        />
      </button>
    </div>
  );
};

export default CallingPopup;
