"use client";
import { useContext, useEffect } from "react";
import LoginFlow from "../../../modules/Astro/LoginFlow";
import { AppContext } from "../../../context/AppContext";
import {
  getUserData,
  getUserWalletData,
} from "../../../services/common.service";
// import ProfilePopup from "../../modules/UserProfile/ProfilePopup";
import LogoutPopup from "../LogoutPopup";
import ReviewPopup from "../ReviewPopup/ReviewPopup";
import { Loader } from "chingari-components";
import Toast from "../Toast";
import CallingPopup from "../CallingPoup";
import CallScreenPopup from "../CallScreenPopup";
import BuyPopUp from "@/modules/Astro/components/BuyPopUp";
import InsufficientBalancePopup from "@/modules/Astro/components/InsufficientBalancePopup";
import PaymentCheckout from "@/modules/Astro/PaymentCheckout";
import { callPopupENUM } from "@/utils/common.types";
import { useRouter, usePathname } from "next/navigation";
// import { getWalletBalance } from "@/services/profile.service";

export default function AuthWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const context = useContext(AppContext);
  const {
    loader,
    auth,
    setAuth,
    setUserData,
    // setWalletBalance,
    popups,
    setPopups = () => null,
    privateCallData,
    setPrivateCallData = () => null,
    setToastData = () => null,
    chatData,
    setChatData = () => null,
    setWalletBalance = () => null,
  } = context ?? {};
  const { showLoginPopup, logoutPopup, downloadPopup } = popups ?? {};

  const getUserDetails = async () => {
    const [userData, walletBal] = await Promise.all([
      getUserData(auth?.userId, auth?.token),
      getUserWalletData(auth?.token),
    ]);
    if (userData) {
      setUserData && setUserData(userData);
    }
    if (walletBal) {
      setWalletBalance(walletBal);
    }
  };

  // const fetchWalletBalance = async () => {
  //   const data = await getWalletBalance(auth?.token);
  //   if (data) {
  //     setWalletBalance && setWalletBalance(data);
  //   }
  // };

  useEffect(() => {
    const token = localStorage.getItem("token") || "";
    const userId = localStorage.getItem("userId") || "";
    setAuth && setAuth({ token, userId });
  }, []);

  useEffect(() => {
    if (auth?.token && auth?.userId && !chatData?.session?.roomId) {
      void getUserDetails();
      // void fetchWalletBalance();
    }
  }, [auth?.token, auth?.userId, chatData?.session?.roomId]);

  const router = useRouter();
  const url = usePathname();
  useEffect(() => {
    if (chatData?.onChat && chatData?.session?.roomId) {
      if (url === `/chat/${chatData?.session?.roomId}`) {
        return;
      }
      router.push(`/chat/${chatData?.session?.roomId}`);
    }
    if (!chatData?.onChat && url === `/chat/${url.split("/")[2]}`) {
      router.replace("/chat");
    }
  }, [chatData, url]);

  return (
    <>
      {children}
      <LoginFlow
        show={!!showLoginPopup}
        onClose={() => setPopups({ showLoginPopup: false })}
      />
      {/* <ProfilePopup
        show={!!isProfilePopup}
        onClose={() => setPopups({ isProfilePopup: false })}
      /> */}
      <LogoutPopup
        show={!!logoutPopup}
        onClose={() => setPopups({ logoutPopup: false })}
      />
      {privateCallData?.reviewPopup && (
        <ReviewPopup
          show={!!privateCallData?.reviewPopup}
          onClose={() => setPrivateCallData({ reviewPopup: false })}
          roomType={url.split("/")[1]}
        />
      )}

      {chatData?.isReviewPopup && (
        <ReviewPopup
          show={!!chatData?.isReviewPopup}
          onClose={() => setChatData({ isReviewPopup: false })}
          roomType={url.split("/")[1]}
        />
      )}

      {loader && (
        <Loader
          loaderContainerStyles={{
            top: 0,
            background: "rgba(0, 0, 0, 0.5)",
          }}
        />
      )}

      {/* {privateCallData?.onCall && (
        <CallScreenPopup
          show={privateCallData?.onCall}
          onClose={() => setPrivateCallData({ onCall: false })}
        />
      )} */}

      {privateCallData?.callPopup && <CallingPopup type={callPopupENUM.CALL} />}
      {chatData?.isChatPopup && <CallingPopup type={callPopupENUM.CHAT} />}
      {privateCallData?.buyPopup && (
        <BuyPopUp
          show={privateCallData?.buyPopup}
          onClose={() => setPrivateCallData({ buyPopup: false })}
        />
      )}

      {privateCallData?.insufficientBalancePopup && (
        <InsufficientBalancePopup
          show={privateCallData?.insufficientBalancePopup}
          onClose={() =>
            setPrivateCallData({ insufficientBalancePopup: false })
          }
        />
      )}

      {chatData?.isInsufficientBalancePopup && (
        <InsufficientBalancePopup
          show={chatData.isInsufficientBalancePopup}
          onClose={() =>
            setChatData({ ...chatData, isInsufficientBalancePopup: false })
          }
        />
      )}
      {privateCallData?.purchasePlan && (
        <PaymentCheckout
          plan={privateCallData?.purchasePlan ?? ""}
          topUpAmount={privateCallData?.topUpAmount ?? 0}
          getUserDetails={getUserDetails}
        />
      )}

      <Toast />
    </>
  );
}
